import cx from "classnames";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { initGA, logPageView } from "../../analytics";
import socket from "../../api/socket";
import NamVotesDisplayAd from "../../components/ads/NamVotesDisplayAd";
import NamVotesMultiAd from "../../components/ads/NamVotesMultiAd";
import NVCharts from "../../components/NVCharts";
import NVNationalAssemblySeats from "../../components/NVNationalAssemblySeats/NVNationalAssemblySeats";
import NVWinnersCircle from "../../components/NVWinnersCircle/NVWinnersCircle";
import styles from "./NVHomePage.module.scss";

export default function NVHomePage() {
  const [traffic, setTraffic] = useState<any>(0);
  useEffect(() => {
    socket.on("traffic", (traffic: any) => {
      setTraffic(traffic);
    });
    initGA();
  }, []);

  // Track page views on route changes
  const TrackPageView = () => {
    const location = window.location;

    useEffect(() => {
      logPageView(location.pathname);
    }, [location]);

    return null;
  };

  return (
    <Container className={cx(styles.appContainer)}>
      <NVWinnersCircle />
      <div className={cx(styles.headerContainer)}>
        <h2 className={cx(["h3", styles.header])}>
          Real-time preliminary 2024 elections results
        </h2>
        <div className={cx(styles.trafficContainer)}>
          <div className={cx(styles.dot)}></div>
          <p className={cx(styles.activeUsers)}>
            active viewers: {traffic < 1 ? "..." : traffic.toFixed(0)}
          </p>
        </div>
      </div>
      <TrackPageView />
      <NVCharts />
      <NamVotesDisplayAd />
      <NVNationalAssemblySeats />
      <br />
      <NamVotesMultiAd />
    </Container>
  );
}
