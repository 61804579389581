import { get } from "./index";

/**
 * /constituencies
 * */

export const getConstituencies = async () => {
    const response = await get("/constituencies");
    return response.data;
}

/**
 * /regions
 * */

export const getRegions = async () => {
    const response = await get("/regions");
    return response.data;
}