import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router";
import NVNavbar from "./components/NVNavbar/NVNavbar";
import NVBackOfficeConstituencyPage from "./pages/NVBackOfficeConstituencyPage/NVBackOfficeConstituencyPage";
import NVBackOfficeHomePage from "./pages/NVBackOfficeHomePage/NVBackOfficeHomePage";
import NVBackOfficePollingStationsPage from "./pages/NVBackOfficePollingStationsPage/NVBackOfficePollingStationsPage";
import NVBOLoginPage from "./pages/NVBOLoginPage/NVBOLoginPage";
import NVCandidatesListPage from "./pages/NVCandidatesListPage/NVCandidatesListPage";
import NVHomePage from "./pages/NVHomePage/NVHomePage";
import NVMapPage from "./pages/NVMapPage/NVMapPage";
import NVParliamentaryConstituencyResultsTablePage from "./pages/NVParliamentaryConstituencyResultsTablePage/NVParliamentaryConstituencyResultsTablePage";
import NVParliamentaryResultsTablePage from "./pages/NVParliamentaryResultsTablePage/NVParliamentaryResultsTablePage";
import NVPartiesListPage from "./pages/NVPartiesListPage/NVPartiesListPage";
import NVPresidentialConstituencyResultsTablePage from "./pages/NVPresidentialConstituencyResultsTablePage/NVPresidentialConstituencyResultsTablePage";
import NVPresidentialResultsTablePage from "./pages/NVPresidentialResultsTablePage/NVPresidentialResultsTablePage";
import NVResultsNationalAssembly from "./pages/NVResults/NVResultsNationalAssembly/NVResultsNationalAssembly";
import NVResultsPresidential from "./pages/NVResults/NVResultsPresidential/NVResultsPresidential";
import {
  default as NVResultsPartyPerPollingStation,
  default as NVResultsPerPollingStation,
} from "./pages/NVResultsPartyPerPollingStation/NVResultsPartyPerPollingStation";
import NVTeamPage from "./pages/NVTeamPage/NVTeamPage";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <NVNavbar />
        <Routes>
          <Route path="/" element={<NVHomePage />} />
          <Route path="/team" element={<NVTeamPage />} />
          <Route path="/map" element={<NVMapPage />} />
          <Route path="/candidates" element={<NVCandidatesListPage />} />
          <Route path="/parties" element={<NVPartiesListPage />} />
          <Route path="/results">
            <Route path="candidates" element={<NVResultsPresidential />} />
            <Route path="parties" element={<NVResultsNationalAssembly />} />
            <Route path="stations">
              <Route
                path="table/presidential"
                element={<NVPresidentialResultsTablePage />}
              />
              <Route
                path="table/parliamentary"
                element={<NVParliamentaryResultsTablePage />}
              />
              <Route
                path="candidates"
                element={<NVResultsPerPollingStation />}
              />
              <Route
                path="parties"
                element={<NVResultsPartyPerPollingStation />}
              />
            </Route>
            <Route path="constituencies">
              <Route
                path="table/presidential"
                element={<NVPresidentialConstituencyResultsTablePage />}
              />
              <Route
                path="table/parliamentary"
                element={<NVParliamentaryConstituencyResultsTablePage />}
              />
            </Route>
          </Route>
          <Route path="/office">
            <Route
              path="station"
              element={<NVBackOfficePollingStationsPage />}
            />
            <Route
              path="constituency"
              element={<NVBackOfficeConstituencyPage />}
            />
            <Route path="home" element={<NVBackOfficeHomePage />} />
            <Route path="login" element={<NVBOLoginPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <div id="disclaimer" style={{ marginTop: "20px" }}>
        <a href="/disclaimer.html" target="_blank" style={{ color: "#ccc" }}>
          disclaimer
        </a>
      </div>
    </>
  );
};

export default App;
