import candidates from "../data/elections.candidates.json";
import parties from "../data/elections.parties.json";
import { Legend, PartyResult, Seat } from "../types";
import { readData, writeData } from "./localStorage";

export function generateRandomColor(): string {
  const randomInt = Math.floor(Math.random() * 0xffffff);
  const hexColor = `#${randomInt.toString(16).padStart(6, "0")}`;
  return hexColor.toUpperCase();
}

export function addCandidateImages(data: any[]): any[] {
  return data.map((candidate) => {
    return {
      ...candidate,
      imageUrl: candidates.find((c) => c.identifier === candidate.identifier)
        ?.imageUrl,
    };
  });
}

export function addPartyImages(data: any[]): any[] {
  return data.map((party) => {
    return {
      ...party,
      imageUrl: parties.find((p) => p.identifier === party.identifier)
        ?.imageUrl,
    };
  });
}

export function getTotalVotes(partyResults: PartyResult[]): number {
  return partyResults.reduce((acc, party) => acc + party.votes, 0);
}

export function calculateVotesPerSeat(
  partyResults: PartyResult[],
  totalSeats: number
): number {
  const totalVotes = getTotalVotes(partyResults);
  return Math.floor(totalVotes / totalSeats);
}

export function calculatePartySeats(
  partyResults: PartyResult[],
  totalSeats: number
): Map<number, [number, number]> {
  const totalVotes = getTotalVotes(partyResults);
  const votesPerSeat = totalVotes / totalSeats;
  // use largest remainder method
  const partySeatsMap = new Map<number, [number, number]>();
  let remainingSeats = totalSeats;

  for (const party of partyResults) {
    const seats = party.votes / votesPerSeat;
    const remainder = party.votes % votesPerSeat;

    partySeatsMap.set(party.identifier, [Math.floor(seats), remainder]);
    remainingSeats -= Math.floor(seats);
  }

  const remainderResults = Array.from(partySeatsMap.entries())
    .map(([identifier, [_seats, remainder]]) => ({
      identifier,
      remainder,
    }))
    .sort((a, b) => b.remainder - a.remainder);

  while (remainingSeats > 0) {
    const party = remainderResults.shift();
    if (!party) break;
    const partySeat = partySeatsMap.get(party.identifier);
    if (!partySeat) continue;
    partySeatsMap.set(party.identifier, [partySeat[0] + 1, 0]);
    remainingSeats--;
  }

  return partySeatsMap;
}

export function getArrayOfPartySeats(
  partyResults: PartyResult[],
  electedSeats: number
): { legend: Legend[]; seats: Seat[]; votesPerSeat: number } {
  const partySeatsMap = calculatePartySeats(partyResults, electedSeats);
  const parliamentSeats: Seat[] = [];
  const legend: Legend[] = [];
  for (const party of partyResults) {
    const [seats] = partySeatsMap.get(party.identifier) ?? [0, 0];
    if (seats > 0) {
      legend.push({
        color: party.color,
        label: party.name,
        votes: party.votes,
        seats,
        image: party.image,
      });
    }
    for (let i = 0; i < seats; i++) {
      parliamentSeats.push({
        identifier: party.identifier,
        party: party.name,
        image: party.image,
        color: party.color,
      });
    }
  }

  const votesPerSeat = calculateVotesPerSeat(partyResults, electedSeats);

  const remainingSeats = electedSeats - parliamentSeats.length;
  for (let i = 0; i < remainingSeats; i++) {
    parliamentSeats.push({
      identifier: 0,
      party: "...",
      image: "",
      color: "#FFF",
    });
  }

  if (remainingSeats > 0) {
    legend.push({
      color: "#DDD",
      label: "NAM - N/A",
      votes: 0,
      seats: remainingSeats,
    });
  }

  legend.sort((a, b) => b.seats - a.seats);
  return { legend, seats: parliamentSeats, votesPerSeat };
}

export function getPartyShortName(partyName: string): string {
  if (partyName === "REPUBLICAN PARTY OF NAMIBIA") {
    return "RP";
  }
  return partyName.split("-")[1];
}

export function showWinnerCelebration() {
  const winnerCelebrationCounter = readData("winnerCelebrationCounter");
  const counter = winnerCelebrationCounter
    ? parseInt(winnerCelebrationCounter)
    : 0;
  if (counter >= 3) {
    return false;
  }
  writeData("winnerCelebrationCounter", counter + 1);
  return true;
}
