import React from 'react'
import cx from 'classnames'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import styles from './NVTeamPage.module.scss'

export default function NVTeamPage() {
  const teamMembers = [
    { id: 1, name: 'Blave Kalahn', role: 'Software Engineer', imageUrl: 'https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg' },
    { id: 1, name: 'Jon Manga', role: 'Software Engineer', imageUrl: 'https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg' },
    { id: 2, name: 'Bob Smith', role: 'Product Manager', imageUrl: 'https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg' },
    { id: 3, name: 'Charlie Brown', role: 'UX Designer', imageUrl: 'https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg' },
    { id: 4, name: 'Diana Green', role: 'Data Scientist', imageUrl: 'https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg' },
    { id: 4, name: 'Diana Green', role: 'Data Scientist', imageUrl: 'https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg' },
    { id: 4, name: 'Diana Green', role: 'Data Scientist', imageUrl: 'https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg' },
    { id: 4, name: 'Diana Green', role: 'Data Scientist', imageUrl: 'https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2220431045.jpg' }
  ]

  return (
    <div className={styles.teamSection}>
      <Container>
        <h2 className={styles.sectionTitle}>Meet Our Team</h2>
        <Row className="g-4">
          {teamMembers.map(member => (
            <Col md={3} key={member.id}>
              <Card className={cx(styles.teamCard, 'shadow')}>
                <Card.Img variant="top" src={member.imageUrl} alt={member.name} />
                <Card.Body>
                  <Card.Title>{member.name}</Card.Title>
                  <Card.Text>{member.role}</Card.Text>
                  <Button variant="primary">Learn More</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
