import cx from "classnames";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table, Toast } from "react-bootstrap";
import { useNavigate } from "react-router";
import Select from "react-select";
import { archiveConstituency as archive, submitReviewConstituency } from "../../api/results";
import loading from "../../assets/loading.gif";
import candidatesData from "../../data/elections.candidates.json";
import partiesData from "../../data/elections.parties.json";
import useAuth from "../../hooks/useAuth";
import useLists from "../../hooks/useLists";
import usePullUnverified from "../../hooks/usePullUnverifiedConstituency";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styles from "./NVBackOfficeConstituencyPage.module.scss";

function NVBackOfficeConstituencyPage() {
  const lists = useLists();
  const { loggedInUser } = useAuth();
  const { unverifiedData, getNextUnverifiedData } = usePullUnverified();
  const [pollingStationUnverifiedData, setPollingStationUnverifiedData] =
    useState<any>(null);
  const [namVotesReviewer, setNamVotesReviewer] = useState<string>("");
  const [returningOfficer, setReturningOfficer] = useState<string>("");
  const [constituency, setConstituency] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [rejectedBallots, setRejectedBallots] = useState<number>(0);
  const [votesCounted, setVotesCounted] = useState<number>(0);
  const [outcome, setOutcome] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sourceFileId, setSourceFileId] = useState<string>("");
  const [showToast, setShowToast] = useState(false);

  const navigate = useNavigate();

  const regionOptions = lists?.regions
    ? lists.regions.map((region: any) => ({
        value: region.name.toUpperCase(),
        label: region.name.toUpperCase(),
      }))
    : [];

  const arithmeticTotal = outcome.reduce((acc, curr) => acc + curr.votes, 0);

  const constituencyOptions = lists?.constituencies
    ? lists.constituencies.map((constituency: any) => ({
        value: constituency.name.toUpperCase(),
        label: constituency.name.toUpperCase(),
        region: constituency.region.toUpperCase(),
      }))
    : [];

  const [filteredConstituencyOptions, setFilteredConstituencyOptions] =
    useState<any[]>(constituencyOptions);

  const handleArchive = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    archive(pollingStationUnverifiedData._id)
      .then(() => {
        handleNext();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const clearAllStates = () => {
    setPollingStationUnverifiedData(null);
    setNamVotesReviewer("");
    setReturningOfficer("");
    setConstituency("");
    setRegion("");
    setRejectedBallots(0);
    setVotesCounted(0);
    setOutcome([]);
    setSourceFileId("");
  };

  const handleNext = () => {
    clearAllStates();
    getNextUnverifiedData();
    setIsLoading(false);
    window.scrollTo(0, 0);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    console.log('Subbmitting data: ', {
      ...pollingStationUnverifiedData,
      returningOfficer,
      constituency,
      region,
      rejectedBallots,
      votesCounted,
      outcome,
    }
    )
    submitReviewConstituency({
      ...pollingStationUnverifiedData,
      returningOfficer,
      constituency,
      region,
      rejectedBallots,
      votesCounted,
      outcome,
    })
      .then(() => {
        handleNext();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    console.log("Unverified Data: ", unverifiedData?._id, unverifiedData?.sourceFile)
    if (region !== "" || region !== null || region !== undefined) {
      const constituencies = constituencyOptions.filter(
        (constituency: any) => constituency.region === region
      );
      setFilteredConstituencyOptions(constituencies);

      setConstituency(
        constituencies.some((c: any) => c.name === constituency)
          ? constituency
          : ""
      );
    } else {
      setFilteredConstituencyOptions(constituencyOptions);
    }
  }, [region]);

  useEffect(() => {
    if (loggedInUser) {
      setNamVotesReviewer(loggedInUser.name);
    } else {
      navigate("/office/login");
    }
  }, [loggedInUser]);
  useEffect(() => {
    if (unverifiedData?.type === "parliamentary") {
      const prefilledOutcome = partiesData.map((party: any) => {
        const unveriefiedData = unverifiedData?.outcome?.find((o: any) => o.identifier === party.identifier)
        return {
          name: unveriefiedData?.name || party.name,
          identifier: unveriefiedData?.identifier || party.identifier,
          votes: unveriefiedData?.votes || 0,
        }
      });
      setOutcome(prefilledOutcome?.sort((a, b)=>a?.identifier - b?.identifier)|| []);
    }else if (unverifiedData?.type === "presidential") {
      const prefilledCandidatesOutcome = candidatesData.map((candidate: any) => {
        const unveriefiedData = unverifiedData?.outcome?.find((o: any) => o.identifier === candidate.identifier)
        return {
          name: unveriefiedData?.name || candidate.name,
          identifier: unveriefiedData?.identifier || candidate.identifier,
          votes: unveriefiedData?.votes || 0,
        }
      });
      setOutcome(prefilledCandidatesOutcome?.sort((a, b)=>a?.identifier - b?.identifier)|| []);
    }else{
      console.log("No data")
    }

    setIsLoading(true);
    if (unverifiedData) {
      setPollingStationUnverifiedData(unverifiedData);
      setSourceFileId(unverifiedData.source || "");
      setReturningOfficer(unverifiedData.returningOfficer || "");
      setConstituency(unverifiedData.constituency || "");
      setRegion(unverifiedData.region || "");
      setRejectedBallots(unverifiedData.rejectedBallots || 0);
      setVotesCounted(unverifiedData.votesCounted || 0);
    }
    setIsLoading(false);
  }, [unverifiedData]);

  const handleVoteChange = (index: number, field: string, value: string) => {
    // Convert to number, removing leading zeros
    const numericValue = parseInt(value.replace(/^0+(?=\d)/, '')) || 0;
    
    setOutcome((prevSections) =>
      prevSections.map((section, idx) =>
        idx === index ? { ...section, [field]: numericValue } : section
      )
    );
  };

  const preventWheelChange = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 2000);
    }).catch(err => {
      console.error('Failed to copy:', err);
    });
  };

  if (!pollingStationUnverifiedData || !lists || isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <img src={loading} alt="loading" />
        <p>Finding new data to review...</p>
      </div>
    );
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.toastContainer}>
        <Toast 
          show={showToast} 
          onClose={() => setShowToast(false)}
          className={styles.toast}
        >
          <Toast.Body>Copied!</Toast.Body>
        </Toast>
      </div>
      <Container fluid className={styles.mainContainer}>
        <Row>
          <Col xs={12} lg={7} className={styles.formSection}>
            <div className={styles.formCard}>
              <div className={styles.formHeader}>
                <h2>Verify Constituency Data</h2>
                {arithmeticTotal !== votesCounted && (
                  <div className={styles.warningBanner}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span>Vote count mismatch detected</span>
                  </div>
                )}
              </div>

              <div className={styles.warningMessage}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <p>Only correct AI errors, not ECN entries. Red highlights indicate potential issues.</p>
              </div>

              <Form onSubmit={handleSubmit}>
                <Form.Group className={styles.formGroup}>
                  <Form.Label>Returning Officer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Returning Officer"
                    value={returningOfficer}
                    onChange={(e) => setReturningOfficer(e.target.value)}
                  />
                </Form.Group>

                <div className={styles.formGrid}>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label>Region</Form.Label>
                    <Select
                      options={regionOptions}
                      value={regionOptions?.find((option: any) => option.value === region)}
                      onChange={(selected) => setRegion(selected?.value || "")}
                      className={styles.select}
                      placeholder="Select Region"
                      isSearchable={false}
                    />
                  </Form.Group>

                  <Form.Group className={styles.formGroup}>
                    <Form.Label>Constituency</Form.Label>
                    <Select
                      options={filteredConstituencyOptions}
                      value={filteredConstituencyOptions?.find(
                        (option: any) => option.value === constituency
                      )}
                      onChange={(selected) => setConstituency(selected?.value || "")}
                      className={styles.select}
                      placeholder="Search Constituency..."
                    />
                  </Form.Group>

                  <Form.Group className={styles.formGroup}>
                    <Form.Label>Rejected Ballots</Form.Label>
                    <Form.Control
                      type="number"
                      value={rejectedBallots}
                      onWheel={preventWheelChange}
                      onChange={(e) => {
                        const numericValue = parseInt(e.target.value.replace(/^0+(?=\d)/, '')) || 0;
                        setRejectedBallots(numericValue);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className={styles.formGroup}>
                    <Form.Label>Total Votes Counted</Form.Label>
                    <Form.Control
                      type="number"
                      value={votesCounted}
                      onWheel={preventWheelChange}
                      className={cx({ [styles.mismatch]: votesCounted !== arithmeticTotal })}
                      onChange={(e) => {
                        const numericValue = parseInt(e.target.value.replace(/^0+(?=\d)/, '')) || 0;
                        setVotesCounted(numericValue);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className={styles.tableWrapper}>
                  <Table striped bordered hover className={styles.votesTable}>
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Candidate/Party</th>
                        <th>Votes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {outcome.map((section, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Form.Control
                              type="text"
                              disabled={true}
                              value={section.name || ""}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              value={section.votes || 0}
                              onWheel={preventWheelChange}
                              onChange={(e) =>
                                handleVoteChange(
                                  index,
                                  "votes",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className={styles.formFooter}>
                  <div className={styles.reviewerInfo}>
                    <Form.Group>
                      <Form.Label>Reviewer</Form.Label>
                      <Form.Control disabled value={namVotesReviewer} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        disabled
                        value={new Date().toLocaleDateString("en-GB")}
                      />
                    </Form.Group>
                  </div>

                  <div className={styles.actionButtons}>
                    <Button
                      variant="success"
                      type="submit"
                      disabled={!region || !constituency}
                      className={styles.submitButton}
                    >
                      <FontAwesomeIcon icon={faCheck} /> Submit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleArchive}
                      disabled={!region || !constituency}
                      className={styles.archiveButton}
                    >
                      <FontAwesomeIcon icon={faArchive} /> Archive
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Col>

          <Col xs={12} lg={5} className={styles.imageSection}>
            <div className={styles.imageCard}>
              <img
                src={`https://lh3.googleusercontent.com/d/${sourceFileId}`}
                alt="poll results"
              />
              <div className={styles.imageInfo}>
                <span>Arithmetic Total: {arithmeticTotal}</span>
                <span>Difference: {arithmeticTotal - votesCounted}</span>
                <span>ID: {unverifiedData?._id}</span>
                <span 
                  className={styles.copyableText}
                  onClick={() => copyToClipboard(unverifiedData?.sourceFile)}
                  title="Click to copy"
                >
                  fileId: {unverifiedData?.source}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NVBackOfficeConstituencyPage;
