import cx from "classnames";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import partiesData from "../../data/elections.parties.json";
import usePartyResults from "../../hooks/usePartyResults";
import { getArrayOfPartySeats, getPartyShortName } from "../../utils";
import styles from "./NVNationalAssemblySeats.module.scss";
const ELECTED_SEATS = 96;

export default function NVNationalAssemblySeats() {
  const parties = usePartyResults();
  const totalVotes =
    (parties ?? []).reduce((acc, curr) => acc + curr.votes, 0) ?? 0;
  const results = parties ?? [];
  const { seats, legend, votesPerSeat } = getArrayOfPartySeats(
    results,
    ELECTED_SEATS
  );

  let indexMap: { [key: number]: any } = {};
  const richSeats = seats.map((currentSeat) => {
    const identifier = currentSeat.identifier;
    if (!indexMap[identifier]) {
      indexMap[identifier] = 0;
    }
    const partyForSeat = partiesData.find((p) => p.identifier === identifier);
    const candidateForSeat: any = partyForSeat?.candidates[
      indexMap[identifier]
    ] || {
      name: false,
      imageUrl: partyForSeat?.imageUrl,
    };
    indexMap[identifier]++;
    return {
      ...currentSeat,
      candidate: candidateForSeat,
      imageUrl: partyForSeat?.imageUrl,
    };
  });

  return (
    <Container className={cx(styles.assemblyContainer)}>
      <h3 className={cx(["h4"])}>Race to parliament</h3>
      <p>
        <small>
          The chart updates in real-time as votes are counted, so check back
          often to stay updated!
        </small>
      </p>
      <p>total votes processed: {totalVotes}</p>
      <div className={cx(styles.layout)}>
        <div className={cx(styles.seatsGrid)}>
          {richSeats.map((seat, index) => (
            <OverlayTrigger
              key={index}
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${index}`}>
                  {`${
                    seat?.candidate?.name !== false
                      ? seat?.candidate?.name + " -"
                      : ""
                  } ${getPartyShortName(seat.party)}`}
                </Tooltip>
              }
            >
              <div
                className={cx(styles.seat)}
                style={{
                  backgroundColor: seat.color,
                  position: "relative",
                }}
              >
                <img
                  src={seat?.candidate?.imageUrl || seat.image}
                  alt={seat?.candidate?.name || seat.party}
                />
                {seat?.candidate?.imageUrl && (
                  <img
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      width: "50%",
                      height: "50%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    src={seat.image}
                    alt="party emblem"
                  />
                )}
                <div
                  className={cx(styles.overlay)}
                  style={{ backgroundColor: seat.color }}
                />
              </div>
            </OverlayTrigger>
          ))}
        </div>

        <div className={cx(styles.legend)}>
          <h4 className={cx(["h5"])}>Legend</h4>
          {legend.map((item, index) => (
            <div className={cx(styles.legendItem)} key={`legend-${index}`}>
              <div
                className={cx(styles.legendColor, styles.elected)}
                style={{
                  backgroundColor: item.color,
                  backgroundBlendMode: "overlay",
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <span>
                {getPartyShortName(item.label)} - ({item.votes}
                {" votes"} | <strong>{item.seats}</strong> seats |{" "}
                <strong>
                  {((item.seats / ELECTED_SEATS) * 100).toFixed(1)}
                </strong>
                % )
              </span>
            </div>
          ))}
          <p className={cx(styles.legendFooter)}>
            <small>*Current {votesPerSeat} votes per seat</small>
          </p>
        </div>
      </div>
    </Container>
  );
}
