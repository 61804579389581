import cx from "classnames";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import candidatesData from "../../data/elections.candidates.json";
import partiesData from "../../data/elections.parties.json";
import { Candidate } from "../../types";
import styles from "./NVCandidateCard.module.scss";

interface NVCandidateCardProps {
  candidate: Candidate;
}

const NVCandidateCard: React.FC<NVCandidateCardProps> = ({ candidate }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <Card
      className={cx(styles.card, flipped && styles.flipped)}
      style={{ width: "100%", height: "25rem" }}
      onClick={handleFlip}
    >
      <div className={cx(styles.cardInner)}>
        {/* Front of the Card */}
        <div className={cx(styles.cardFront)}>
          <Card.Img variant="top" src={candidate?.imageUrl} />
          <Card.Body>
            <Card.Title>{candidate?.name}</Card.Title>
            <div className={cx(styles.socialLinks)}>
              <img
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "1px solid #000",
                }}
                alt={`${candidate?.name}`}
                src={
                  partiesData.find(
                    (p) =>
                      p.identifier ===
                      candidatesData.find(
                        (c) => c.identifier === candidate?.identifier
                      )?.partyIdentifier
                  )?.imageUrl
                }
              />
            </div>
          </Card.Body>
        </div>

        {/* Back of the Card */}
        <div className={cx(styles.cardBack)}>
          <Card.Body>
            <Card.Title>{candidate?.name}</Card.Title>
            <Card.Text>
              {candidatesData.find(
                (c) => c.identifier === candidate?.identifier
              )?.description || ""}
            </Card.Text>
          </Card.Body>
        </div>
      </div>
    </Card>
  );
};

export default NVCandidateCard;
