import { useEffect } from "react";
import { pullUnverified } from "../api/results";
import useOurState, { updateState } from "./state";
import { readData } from "../utils/localStorage";

export default function usePullUnverified() {
  const state = useOurState();

  useEffect(() => {
    if (!state.data.loggedInUser) {
      const user = readData("user");
      if (user) {
        updateState({ loggedInUser: user });
      }
    }
    const user = state.data.loggedInUser as { username: string; password: string };
    pullUnverified({
        username: user?.username,
        password: user?.password,
    }).then((unverifiedData) => {
      updateState({
        unverifiedData,
      });
      return unverifiedData;
    });
  }, []);

  const getNextUnverifiedData = () => {
    const user = state.data.loggedInUser as { username: string; password: string };
    pullUnverified({
      username: user?.username,
      password: user?.password,
    }).then((unverifiedData) => {
      updateState({
        unverifiedData,
      });
      return unverifiedData;
    });
  }

  return {
    unverifiedData: state.data.unverifiedData as any,
    getNextUnverifiedData: getNextUnverifiedData,
  }
}
