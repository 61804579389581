import { useEffect } from "react";
import { getPartyRegionWinners } from "../api/results";
import useOurState, { updateState } from "./state";
import partiesData from "../data/elections.parties.json";

export default function useRegionWinners(): any[] {
  const state = useOurState();

  useEffect(() => {
    getPartyRegionWinners().then((results: any[]) => {
      const richPartyData = results.map((result) => {
        const partyData = partiesData.find((party) => party.identifier === result.winner);
        return {
          ...result,
          imageUrl: partyData?.imageUrl,
          partyName: partyData?.name,
        };
      });
      updateState({
        partyRegionWinners: richPartyData,
      });
    });
  }, []);
  
  return state?.data?.partyRegionWinners as any[] || [];
}
