import * as echarts from "echarts";
import { useEffect, useRef } from "react";
import { CandidateResult } from "../../../types";

export default function RunoffGraph({
  candidates,
}: {
  candidates: CandidateResult[];
}) {
  const chartRef = useRef<HTMLDivElement>(null);
  const chart = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    window.addEventListener("resize", () => {
      chart.current?.resize();
    });

    return () => {
      window.removeEventListener("resize", () => {
        chart.current?.resize();
      });
    };
  }, []);

  useEffect(() => {
    const options = chart?.current?.getOption();
    if (options && options.series && candidates.length > 0) {
      const _options = getOptions(candidates);
      chart.current?.setOption(_options);
      return;
    }
  }, [candidates]);

  useEffect(() => {
    if (chartRef.current && !chart.current && candidates.length > 0) {
      chart.current = echarts.init(chartRef.current);
      const option = getOptions(candidates);
      chart.current?.setOption(option);
    }
  }, [chart, chartRef, candidates]);
  return <div ref={chartRef} style={{ width: "100%", height: "100%" }} />;
}

function getSeries(
  candidate: { name: string; votes: number },
  totalVotes: number,
  overrides?: Partial<echarts.SeriesOption["data"]>
) {
  return {
    name: candidate.name,
    type: "bar",
    stack: "total",
    label: {
      show: true,
      formatter: (params: any) => {
        return `${(params.value * 100).toFixed(2)}%`;
      },
    },
    emphasis: {
      focus: "series",
    },
    ...overrides,
    data: [candidate.votes / totalVotes],
  };
}

function getOptions(candidates: CandidateResult[]) {
  const totalVotes = candidates.reduce(
    (acc, candidate) => acc + candidate.votes,
    0
  );
  const top1 = candidates[0];
  const top2 = candidates[1];
  const others = {
    name: "Others",
    votes: candidates
      .slice(2)
      .reduce((acc, candidate) => acc + candidate.votes, 0),
  };

  const series = [
    getSeries(top1, totalVotes, {
      barWidth: 30,
      itemStyle: {
        color: "#FF0000",
        borderRadius: [15, 0, 0, 15],
      },
    }),
    getSeries(others, totalVotes, {
      color: "#DDD",
    }),
    getSeries(top2, totalVotes, {
      itemStyle: {
        color: "#0000FF",
        borderRadius: [0, 15, 15, 0],
      },
    }),
  ];

  return {
    tooltip: {
      show: false,
    },
    legend: {
      show: false,
    },
    textStyle: {
      fontWeight: "bold",
    } as any,
    grid: {
      show: false,
      left: 0,
      right: 0,
      top: 5,
      bottom: 5,
    },
    xAxis: {
      show: false,
    },
    yAxis: [
      {
        type: "category",
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
    ],
    series: [
      ...series,
      {
        name: `50% mark of votes`,
        type: "line",
        stack: "total",
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        markLine: {
          symbol: "circle",
          data: [
            {
              xAxis: 0.5,
              lineStyle: {
                type: "dotted",
                width: 5,
                color: "#000000",
              },
              symbol: "circle",
              label: {
                position: "middle",
                formatter: "50%",
              },
            },
          ],
        },
      },
    ],
  };
}
