import cx from "classnames";
import React from "react";
import { Container } from "react-bootstrap";
import { Party } from "../../types";
import styles from "./NVPartiesList.module.scss";
import NVPartyCard from "./NVPartyCard";

interface NVPartiesListProps {
  parties: Party[];
}

const NVPartiesList: React.FC<NVPartiesListProps> = ({ parties }) => {
  return (
    <Container className={cx(styles.container)}>
      <h2>Political parties for the 2024 National Assembly Elections</h2>
      {parties.map((party) => (
        <div key={party.name} className={cx(styles.cardWrapper)}>
          <NVPartyCard party={party} />
        </div>
      ))}
    </Container>
  );
};

export default NVPartiesList;
