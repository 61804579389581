import { useEffect } from "react";
import { getResults, ResultType } from "../api/results";
import { ParliamentResult } from "../types";
import useOurState, { updateState } from "./state";

export default function useParliamentaryResults():
  | ParliamentResult[]
  | undefined {
  const state = useOurState();

  useEffect(() => {
    getResults(ResultType.PARLIAMENTARY, {
      isVerified: "true",
    })
      .then((results: ParliamentResult[]) => {
        updateState({
          parliamentaryResults: results,
        });
      })
      .catch((error) => {
        // ignore
      });
  }, []);
  return state?.data?.parliamentaryResults as ParliamentResult[] | undefined;
}
