export const writeData = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const readData = (key: string) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
}

export const clearData = (key: string) => {
    localStorage.removeItem(key);
}