import { useEffect } from "react";
import { getResults, ResultType } from "../api/results";
import { PartyResult } from "../types";
import useOurState, { updateState } from "./state";

export default function usePartyResults(): PartyResult[] | undefined {
  const state = useOurState();

  useEffect(() => {
    getResults(ResultType.PARTIES)
      .then((results: PartyResult[]) => {
        results.sort((a, b) => b.votes - a.votes);
        updateState({
          partyResults: results,
        });
      })
      .catch((error) => {
        // ignore
      });
  }, []);

  return state?.data?.partyResults as PartyResult[] | undefined;
}
