import cx from "classnames";
import { Container } from "react-bootstrap";
import NVPartiesList from "../../components/NVPartyCard/NVPartiesList";
import parties from "../../data/elections.parties.json";
import { Party } from "../../types";
import styles from "./NVPartiesListPage.module.scss";

interface NVPartiesListProps {
  parties: Party[];
}

export default function NVPartiesListPage() {
  return (
    <Container className={cx(styles.container)}>
      <NVPartiesList parties={parties as unknown as Party[]} />
    </Container>
  );
}
