import { post } from "./index";
import { writeData } from "../utils/localStorage";

export const login = async (authData: any) => {
    try {
      const response = await post("/admin/login", authData);
      if (response?.data && response?.data?.username && response?.data?.name) {
        writeData("user", {...response.data, password: authData.password});
        return response.data;
      }
      throw new Error("Invalid login response");
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };
  
