import cx from "classnames";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { initGA } from "../../analytics";
import { getResults, ResultType } from "../../api/results";
import NVBarGraph from "../../components/NVCharts/NVBarGraph/NVBarGraph";
import partiesData from "../../data/elections.parties.json";
import styles from "./NVResultsPartyPerPollingStation.module.scss";

export default function NVResultsPartyPerPollingStation() {
  const [results, setResults] = useState<any[]>([]);
  useEffect(() => {
    getResults(ResultType.PARLIAMENTARY)
      .then((data) => {
        setResults(data);
        console.log("Data: ", data);
      })
      .catch((error) => {
        // ignore
      });
    initGA(); // Initialize Google Analytics
  }, []);
  return (
    <Container className={cx(styles.container)}>
      <h1>Results per Polling Station</h1>
      <p>This page shows the results per polling station.</p>
      <Container className={cx(styles.cardsContainer)}>
        {results.map((result, index) => (
          <Container className={cx(styles.cardContainer)}>
            <section className={cx(styles.metadata)}>
              <h1>Station: {result?.pollingStation}</h1>
              <h3>Presiding Officer: {result?.presidingOfficer}</h3>
              <div className={cx(styles.metacards)}>
                <div className={cx(styles.card)}>
                  <p>rejected ballots: {result?.rejectedBallots}</p>
                  <p>total votes: {result?.votesCounted}</p>
                </div>
                <div className={cx(styles.card)}>
                  <p>Region: {result?.region}</p>
                  <p>Constituency: {result?.constituency}</p>
                </div>
              </div>
            </section>
            <section key={index} className={cx(styles.mainSection)}>
              <NVBarGraph
                candidates={result?.outcome
                  .sort((a: any, b: any) => b.votes - a.votes)
                  .map((a: any) => {
                    return {
                      name: a?.name,
                      votes: a?.votes,
                      image: partiesData.find(
                        (c) => c.identifier === a.identifier
                      )?.imageUrl,
                    };
                  })}
              />
            </section>
          </Container>
        ))}
      </Container>
    </Container>
  );
}
