import { get } from "./index";

/**
 * /candidates
 * */

export const getCandidates = async () => {
  const response = await get("/candidates");
  return response.data;
};

export async function getParties() {
  const response = await get("/parties");
  return response.data;
}
