import NVPollingStationsTable from "../../components/NVPollingStationsTable/NVPollingStationsTable";
import useParliamentaryResults from "../../hooks/useParliamentaryResults";
import useParties from "../../hooks/useParties";

export default function NVParliamentaryResultsTablePage() {
  const results = useParliamentaryResults();
  const parties = useParties() || [];
  parties.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <NVPollingStationsTable
      results={results!}
      headerText="Polling Stations"
      type="parliamentary"
      candidates={parties}
    />
  );
}
