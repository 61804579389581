import { useEffect } from "react";
import { getResults, ResultType } from "../api/results";
import { CandidateResult } from "../types";
import useOurState, { updateState } from "./state";

export default function useCandidateResults(): CandidateResult[] | undefined {
  const state = useOurState();

  useEffect(() => {
    getResults(ResultType.CANDIDATES)
      .then((results: CandidateResult[]) => {
        results.sort((a, b) => b.votes - a.votes);
        updateState({
          candidatesResults: results,
        });
      })
      .catch((error) => {
        // ignore
      });
  }, []);

  return state?.data?.candidatesResults as CandidateResult[] | undefined;
}
