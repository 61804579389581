import { useEffect } from "react";
import { getConstituencyResults, ResultType } from "../api/results";
import { CandidateResult } from "../types";
import useOurState, { updateState } from "./state";

export default function usePresidentialConstituencyResults(): CandidateResult[] | undefined {
  const state = useOurState();

  useEffect(() => {
    getConstituencyResults(ResultType.PRESIDENTIAL, {
      isVerified: "true",
    })
      .then((results: CandidateResult[]) => {
        updateState({
          presidentialConstituencyResults: results,
        });
      })
      .catch((error: any) => {
      });
  }, []);
  return state?.data?.presidentialConstituencyResults as CandidateResult[] | undefined;
}
