import cx from "classnames";
import React from "react";
import { Container } from "react-bootstrap";
import { Candidate } from "../../types";
import NVCandidateCard from "./NVCandidateCard";
import styles from "./NVCandidatesList.module.scss";

interface NVCandidatesListProps {
  candidates: Candidate[];
}

const NVCandidatesList: React.FC<NVCandidatesListProps> = ({ candidates }) => {
  return (
    <>
      <h1>Namibian Presidential Candidates</h1>
      <Container className={cx(styles.container)}>
        {candidates.map((candidate) => (
          <div
            key={`candidate-${candidate.name}`}
            className={cx(styles.cardWrapper)}
          >
            <NVCandidateCard candidate={candidate} />
          </div>
        ))}
      </Container>
    </>
  );
};

export default NVCandidatesList;
