import { useEffect } from "react";
import { getCandidates } from "../api/candidates";
import { Candidate } from "../types";
import useOurState, { updateState } from "./state";

export default function useCandidate(): Candidate[] | undefined {
  const state = useOurState();

  useEffect(() => {
    getCandidates().then((candidates) => {
      updateState({
        candidates,
      });
    });
  }, []);

  return state?.data?.candidates as Candidate[] | undefined;
}
