import cx from "classnames";
import * as echarts from "echarts";
import React, { useEffect, useRef } from "react";
import styles from "./NVBarGraph.module.scss";

const MINIMUM_PERCENTAGE_CUT_OFF = 3;

interface Candidate {
  name: string;
  votes: number;
  image: string; // URL or base64 string of the candidate's image
}

interface NVBarGraphProps {
  candidates: Candidate[];
  totalVotes?: number;
}

const seriesLabel = {
  show: true,
  formatter: (params: any) => {
    return params.value[2] > MINIMUM_PERCENTAGE_CUT_OFF
      ? `${params.value[1]} (${params.value[2]}%)`
      : `${params.value[1]}`;
  },
};

const candidateImageSize = 35;

function getId(name: string) {
  return name.split(/\s|-/)[0];
}
const NVBarGraph: React.FC<NVBarGraphProps> = ({ candidates, totalVotes }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chart = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    window.addEventListener("resize", () => {
      chart.current?.resize();
    });

    return () => {
      window.removeEventListener("resize", () => {
        chart.current?.resize();
      });
    };
  }, []);

  useEffect(() => {
    const options = chart?.current?.getOption();
    if (options && options.series) {
      const _options = getOptions(candidates, totalVotes);
      chart.current?.setOption(_options);
      return;
    }
  }, [candidates]);

  useEffect(() => {
    if (chartRef.current && !chart.current && candidates.length > 0) {
      chart.current = echarts.init(chartRef.current);
      const option = getOptions(candidates, totalVotes);
      chart.current?.setOption(option);
    }
  }, [candidates, chart, chartRef]);

  return (
    <div
      className={cx(styles.canvasWrapper)}
      ref={chartRef}
      style={{ width: "100%", minHeight: "40rem" }}
    />
  );
};

export default NVBarGraph;

function getOptions(candidates: Candidate[], totalVotes?: number) {
  const candidateImages = getCandidateImages(candidates);
  return {
    tooltip: {
      trigger: "axis",
      confine: true,
      extraCssText: "z-index: 900;",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: 45,
      top: 20,
      right: 5,
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "value",
      name: "Votes",
      max: "dataMax",
      axisLabel: {
        formatter: "{value}",
        align: "right",
      },
    },
    yAxis: {
      type: "category",
      inverse: true,

      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        formatter: function (value: string) {
          const name = getId(value);
          return "{" + name + "| }";
        },
        borderRadius: candidateImageSize,
        rich: candidateImages,
      },
    },
    dataset: [
      {
        source: candidates
          .sort((a, b) => b.votes - a.votes)
          .map(({ name, votes }) => [
            name,
            votes,
            parseFloat(((votes / totalVotes!) * 100).toFixed(2)),
          ]),
      },
    ],
    series: [
      {
        name: "votes",
        type: "bar",
        realtimeSort: true,
        showBackground: true,
        label: seriesLabel,
        itemStyle: {
          borderRadius: [0, 15, 15, 0],
        },
      },
    ],
  };
}

function getCandidateImages(candidates: Candidate[]) {
  return candidates.reduce((acc, candidate) => {
    return Object.assign(acc, {
      [getId(candidate.name)]: {
        backgroundColor: {
          image: candidate.image,
        },
        height: candidateImageSize,
        width: candidateImageSize,
        align: "center",
        borderRadius: candidateImageSize,
      },
    });
  }, {});
}
