import { readData } from "../utils/localStorage";
import { get, getWithBasicAuth, postWithBasicAuth } from "./index";

/**
 * /results/presidential
 * /results/parties
 * /results/parliamentary
 */

export enum ResultType {
  PRESIDENTIAL = "presidential",
  PARTIES = "parties",
  PARLIAMENTARY = "parliamentary",
  CANDIDATES = "candidates",
  CONSTITUENCY = "constituency",
}

export const getResults = async (
  type: string,
  query?: Record<string, string>
) => {
  const _query = query ? `?${new URLSearchParams(query).toString()}` : "";
  const response = await get(`/results/${type}${_query}`);
  return response?.data;
};

export const pullUnverified = async (authData: {
  username: string;
  password: string;
}) => {
  const response = await getWithBasicAuth(
    `/admin/pull-unverified`,
    authData.username,
    authData.password
  );
  return response;
};

export const pullUnverifiedConstituency = async (authData: {
  username: string;
  password: string;
}) => {
  const response = await getWithBasicAuth(
    `admin/pull-unverified-constituency`,
    authData.username,
    authData.password
  );
  return response;
};

export const submitReview = async (authData: { data: any }) => {
  const user = readData("user");
  const response = await postWithBasicAuth(
    `/admin/verify`,
    authData,
    user.username,
    user.password
  );
  return response;
};

export const submitReviewConstituency = async (authData: { data: any }) => {
  const user = readData("user");
  const response = await postWithBasicAuth(
    `admin/verify-constituency`,
    authData,
    user.username,
    user.password
  );
  return response;
};

export const archive = async (id: string) => {
  const user = readData("user");
  const response = await postWithBasicAuth(
    `/admin/archive/${id}`,
    {},
    user.username,
    user.password
  );
  return response;
};

export const archiveConstituency = async (id: string) => {
  const user = readData("user");
  const response = await postWithBasicAuth(
    `/admin/archive-constituency/${id}`,
    {},
    user.username,
    user.password
  );
  return response;
};

export const getPartyRegionWinners = async () => {
  const response = await get(`/results/parties/regions/winners`);
  return response.data;
};

export const getConstituencyResults = async (
  type: string,
  query?: Record<string, string>
) => {
  const _query = query ? `?${new URLSearchParams(query).toString()}` : "";
  const response = await get(`/results/constituencies-${type}${_query}`);
  return response?.data;
};
