import confetti from "canvas-confetti";
import cx from "classnames";
import { useEffect, useState } from "react";
import candidates from "../../data/elections.candidates.json";
import parties from "../../data/elections.parties.json";
import { getPartyShortName, showWinnerCelebration } from "../../utils";
import styles from "./NVWinnersCircle.module.scss";

export default function NVWinnersCircle() {
  const [winnerCandidate, setWinnerCandidate] = useState<any>(null);
  const [winnerParty, setWinnerParty] = useState<any>(null);
  const [isOverlayVisible, setOverlayVisible] = useState<boolean>(false);

  useEffect(() => {
    setOverlayVisible(showWinnerCelebration());
    const winnerPerson = candidates.find(
      (candidate: any) => candidate?.identifier === 11
    );
    const winnerParti = parties.find((party: any) => party?.identifier === 18);
    setWinnerCandidate(winnerPerson);
    setWinnerParty(winnerParti);
  }, []);

  useEffect(() => {
    let confettiInterval: NodeJS.Timeout | null = null;

    function randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min;
    }

    function renderConfetti() {
      const colors = ["#FFFFFF", "#FFEB3B", "#2196F3", "#F44336", "#4CAF50"];

      confetti({
        particleCount: 5,
        startVelocity: 20,
        spread: 360,
        origin: {
          x: Math.random(),
          y: Math.random() * 0.5, // Confetti starts from the upper half of the screen
        },
        colors: colors,
        gravity: randomInRange(0.4, 0.6),
        scalar: randomInRange(0.6, 1.2),
        drift: randomInRange(-0.4, 0.4),
      });
    }

    if (isOverlayVisible) {
      confettiInterval = setInterval(renderConfetti, 50); // Run confetti every second
    }

    return () => {
      if (confettiInterval) {
        clearInterval(confettiInterval); // Clear the interval when the component unmounts or overlay is closed
      }
    };
  }, [isOverlayVisible]);

  const closeOverlay = () => {
    setOverlayVisible(false);
  };

  return (
    <div
      className={cx(styles.container, { [styles.hidden]: !isOverlayVisible })}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <h1 className={styles.title}>Winners Circle</h1>
          <button onClick={closeOverlay} className={styles.closeButton}>
            &times;
          </button>
        </div>
        <div className={styles.winners}>
          <div className={styles.imageWrapper}>
            {winnerCandidate && (
              <>
                <img
                  src={winnerCandidate?.imageUrl}
                  alt={winnerCandidate?.name}
                  className={styles.circleImage}
                />
                <p className={styles.description}>{winnerCandidate?.name}</p>
                <p className={styles.description}>
                  {getPartyShortName(winnerCandidate?.party || "")}
                </p>
              </>
            )}
          </div>
          <div className={styles.imageWrapper}>
            {winnerParty && (
              <>
                <img
                  src={winnerParty?.imageUrl}
                  alt={winnerParty?.name}
                  className={styles.circleImage}
                />
                <p className={styles.description}>
                  {winnerParty?.name?.split("-")?.[0]}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
