import { useEffect } from "react";
import { pullUnverifiedConstituency } from "../api/results";
import { readData } from "../utils/localStorage";
import useOurState, { updateState } from "./state";

export default function usePullUnverifiedConstituency() {
  const state = useOurState();

  useEffect(() => {
    if (!state.data.loggedInUser) {
      const user = readData("user");
      if (user) {
        updateState({ loggedInUser: user });
      }
    }
    const user = state.data.loggedInUser as { username: string; password: string };
    pullUnverifiedConstituency({
        username: user?.username,
        password: user?.password,
    }).then((unverifiedData) => {
      updateState({
        unverifiedDataConstituency: unverifiedData,
      });
      return unverifiedData;
    });
  }, []);

  const getNextUnverifiedData = () => {
    const user = state.data.loggedInUser as { username: string; password: string };
    pullUnverifiedConstituency({
      username: user?.username,
      password: user?.password,
    }).then((unverifiedData) => {
      updateState({
        unverifiedDataConstituency: unverifiedData,
      });
      return unverifiedData;
    });
  }

  return {
    unverifiedData: state.data.unverifiedDataConstituency as any,
    getNextUnverifiedData: getNextUnverifiedData,
  }
}
