import cx from "classnames";
import * as echarts from "echarts";
import React, { useEffect, useRef, useState } from "react";
import populationStats from "../../data/population.json";
import useParties from "../../hooks/useParties";
import useRegionWinners from "../../hooks/useRegionWinners";
import { getPartyShortName } from "../../utils";
import styles from "./NVEMap.module.scss";

const NAMIBIA_GEOJSON_URL = `https://assets.ctfassets.net/26zm6w0h0235/Iwcu9ovOesfhfyhONWEhk/0bab508b378a5bfcc6f45a8bf231de5e/nam.geojson?t=${Date.now()}`;

const NVEMap: React.FC = () => {
  const regionsWinners = useRegionWinners();
  const chartRef = useRef<HTMLDivElement>(null);
  const chart = useRef<echarts.ECharts | null>(null);
  const parties = useParties();
  const [geoJson, setGeoJson] = useState<any>(null);

  useEffect(() => {
    // Resize chart on window resize
    const handleResize = () => chart.current?.resize();
    window.addEventListener("resize", handleResize);

    fetch(NAMIBIA_GEOJSON_URL).then(async (response) => {
      const json = await response.json();
      setGeoJson(json);
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (chartRef.current && !chart.current) {
      chart.current = echarts.init(chartRef.current);
      chart.current.showLoading();
    }
  }, [chart, chartRef]);

  useEffect(() => {
    if (
      chartRef.current &&
      chart.current &&
      geoJson &&
      regionsWinners?.length &&
      parties?.length
    ) {
      echarts.registerMap("Namibia", geoJson);

      const option = {
        title: {
          text: "Who won the region?",
          subtext: "#namvotes24",
          left: "left",
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params: any) {
            return params?.data?.partyName
              ? `${getPartyShortName(params?.data?.partyName)} leads with ${
                  params?.data?.votes
                } votes.`
              : `${params?.name} has no data yet.`;
          },
        },
        visualMap: {
          left: "right",
          bottom: "12%",
          calculable: true,
          type: "piecewise",
          pieces: parties.map(({ identifier, name, color }) => ({
            value: identifier,
            label: getPartyShortName(name),
            color,
          })),
        },
        toolbox: {
          show: true,
          left: "right",
          top: "top",
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          show: false,
          top: 0,
          bottom: 0,
        },
        series: [
          {
            name: "Regional winners",
            type: "map",
            roam: false,
            zoom: 1.1,
            map: "Namibia",
            emphasis: {
              label: {
                show: true,
                formatter: function (params: any) {
                  return (
                    params.data?.name +
                    "\n" +
                    " population " +
                    params.data?.regionData?.population +
                    "\n" +
                    " reg voters " +
                    params.data?.regionData?.RegisteredVoters
                  );
                },
              },
            },
            data: regionsWinners.map(({ name, winner, votes, partyName }) => ({
              name,
              value: winner,
              votes,
              partyName,
              regionData: populationStats.find(
                (region) => region.name === name
              ),
            })),
          },
        ],
      };
      chart.current.hideLoading();
      chart.current.setOption(option);
    }
  }, [parties, regionsWinners, geoJson, chart, chartRef]);

  return (
    <div
      className={cx(styles.canvasWrapper)}
      ref={chartRef}
      style={{
        width: "100%",
        minHeight: "50rem",
        maxHeight: "55vh",
        marginTop: "20px",
      }}
    />
  );
};

export default NVEMap;
