import { useEffect } from "react";
import { getParties } from "../api/candidates";
import { Party } from "../types";
import useOurState, { updateState } from "./state";

export default function useParties(): Party[] {
  const state = useOurState();
  useEffect(() => {
    getParties().then((parties) => {
      updateState({
        parties,
      });
    });
  }, []);

  return state?.data?.parties as Party[];
}
