import { useEffect } from "react";
import { getResults, ResultType } from "../api/results";
import { CandidateResult } from "../types";
import useOurState, { updateState } from "./state";

export default function usePresidentialResults():
  | CandidateResult[]
  | undefined {
  const state = useOurState();

  useEffect(() => {
    getResults(ResultType.PRESIDENTIAL, {
      isVerified: "true",
    })
      .then((results: CandidateResult[]) => {
        updateState({
          presidentialResults: results,
        });
      })
      .catch((error) => {
        // ignore
      });
  }, []);
  return state?.data?.presidentialResults as CandidateResult[] | undefined;
}
