import NVBarGraph from "../../../components/NVCharts/NVBarGraph/NVBarGraph"
import { Container } from 'react-bootstrap';
import cx from 'classnames';
import styles from './NVResultsPresidential.module.scss';
import useCandidateResults from "../../../hooks/useCandidateResults";

export default function NVResultsPresidential() {
  const results = useCandidateResults();
  return (
    <Container className={cx(styles.container)}>
        <h1>Results for Presidential Results</h1>
        <NVBarGraph candidates={results ?? []}/>
    </Container>
  )
}
