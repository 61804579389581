import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import styles from "./NVBOLoginPage.module.scss";

const NVBOLoginPage = () => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const { loggedInUser, loginUser, error } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedInUser) {
            navigate("/office/home");
        }
    }, [loggedInUser, navigate]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            await loginUser({ username, password });
            navigate("/office/home");
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    return (
        <div className={cx(styles.loginPage)}>
            <Card className={cx(styles.loginCard)}>
                <h2>Login</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formUserName">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter username"
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Login
                    </Button>

                    {error && (
                        <div style={{ color: "red", marginTop: "1rem" }}>
                            {error.message}
                        </div>
                    )}

                    <div className={cx(styles.forgotPassword)}>
                        <a href="#forgot-password">Forgot Password?</a>
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default NVBOLoginPage;
