import NVConstituenciesTable from "../../components/NVConstituenciesTable/NVConstituenciesTable";
import useParliamentaryConstituencyResults from "../../hooks/useParliamentaryConstituencyResults";
import useParties from "../../hooks/useParties";

export default function NVParliamentaryConstituencyResultsTablePage() {
  const results = useParliamentaryConstituencyResults();
  const parties = useParties() || [];
  return (
    <NVConstituenciesTable
      results={results || []}
      headerText="Constituency"
      type="parliamentary"
      candidates={parties}
    />
  );
}
