import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Party } from "../../types";
import styles from "./NVPartyCard.module.scss";

interface NVPartyCardProps {
  party: Party;
}

const NVPartyCard: React.FC<NVPartyCardProps> = ({ party }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <Card
      className={cx(styles.card, flipped && styles.flipped)}
      style={{ width: "100%", height: "30rem" }}
      onClick={handleFlip}
    >
      <div className={cx(styles.cardInner)}>
        {/* Front of the Card */}
        <div className={cx(styles.cardFront)}>
          <Card.Img variant="top" src={party?.image} />
          <Card.Body>
            <Card.Title>{party?.name}</Card.Title>
          </Card.Body>
        </div>

        {/* Back of the Card */}
        <div className={cx(styles.cardBack)}>
          <Card.Body>
            <Card.Title>{party?.name}</Card.Title>
            {/* <Card.Text>
              {party?.description || "Short description about the candidate goes here."}
            </Card.Text> */}
            {party?.contact.map((contact: any, index: number) => (
              <div className={cx(styles.contactInfo)} key={`contact-${index}`}>
                <span>{contact?.contactPerson}</span>
                <div className={cx(styles.socialLinks)}>
                  <FontAwesomeIcon icon={faPhone} />
                  <FontAwesomeIcon icon={faEnvelope} />
                  <FontAwesomeIcon icon={faFacebook} />
                  <FontAwesomeIcon icon={faTwitter} />
                  <FontAwesomeIcon icon={faInstagram} />
                </div>
              </div>
            ))}
          </Card.Body>
        </div>
      </div>
    </Card>
  );
};

export default NVPartyCard;
