import React, {useEffect, useState} from 'react'
import styles from './NVCandidatesListPage.module.scss';
import cx from 'classnames';
import { Container } from 'react-bootstrap';
import NVCandidatesList from '../../components/NVCandidateCard/NVCandidatesList';
import { getCandidates } from '../../api/candidates';

const NetumboIdentifier = 11;

export default function NVCandidatesListPage() {
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    getCandidates().then((data) => {
      setCandidates(data.sort((a:any, b:any) => {
        if (a.identifier === NetumboIdentifier) return -1;
        if (b.identifier === NetumboIdentifier) return 1;
        return a.votes - b.votes;
      }));
    });
  }, []);

  return (
    <Container className={cx(styles.container)}>
      <NVCandidatesList candidates={candidates || []} />
    </Container>
  )
}
