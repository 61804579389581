import NVConstituenciesTable from "../../components/NVConstituenciesTable/NVConstituenciesTable";
import useCandidate from "../../hooks/useCandidate";
import usePresidentialConstituencyResults from "../../hooks/usePresidentialConstituencyResults";

export default function NVPresidentialConstituencyTablePage() {
  const results = usePresidentialConstituencyResults();
  const candidates = useCandidate() || [];
  return (
    <NVConstituenciesTable
      results={results || []}
      headerText="Constituency"
      type="presidential"
      candidates={candidates}
    />
  );
}
