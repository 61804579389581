import cx from 'classnames';
import { Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import styles from './NVBackOfficeHomePage.module.scss';

export default function NVBackOfficeHomePage() {
  return (
    <Container className={cx(styles.container)}>
      <div className={styles.header}>
        <h1>Back Office Dashboard</h1>
        <p>Select the type of data you wish to manage</p>
      </div>
      
      <div className={styles.cardContainer}>
        <Card className={styles.optionCard} onClick={() => window.location.href = '/office/constituency'}>
          <div className={styles.iconWrapper}>
            <FontAwesomeIcon icon={faBuilding} size="2x" />
          </div>
          <Card.Body>
            <Card.Title>Constituency Data</Card.Title>
            <Card.Text>
              Manage and review constituency-level election results
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className={styles.optionCard} onClick={() => window.location.href = '/office/station'}>
          <div className={styles.iconWrapper}>
            <FontAwesomeIcon icon={faVoteYea} size="2x" />
          </div>
          <Card.Body>
            <Card.Title>Polling Station Data</Card.Title>
            <Card.Text>
              Manage and review polling station-level election results
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
