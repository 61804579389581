import axios, { AxiosResponse } from "axios";
import { API_SERVER } from "./server";

const TOKEN_MOCK = null;

const api = axios.create({
  baseURL: API_SERVER,
  withCredentials: false,
  headers: {
    Authorization: `Bearer ${TOKEN_MOCK}`,
  },
});

api.interceptors.request.use(
  (config: any) => {
    if (
      config.headers &&
      config.headers.Authorization &&
      config.headers.Authorization !== "Bearer null"
    ) {
      return config;
    }

    if (TOKEN_MOCK) {
      config.headers.Authorization = `Bearer ${TOKEN_MOCK}`;
      config.headers["Content-Type"] = "application/json";
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

export const get = async (url: string, options: any = null): Promise<any> => {
  const response: AxiosResponse = await api.get(url, options);
  return response;
};

export const getWithBasicAuth = async (
  url: string,
  username: string,
  password: string
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.get(url, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during GET request:", error);
    throw error;
  }
};

export const post = async (url: string, data: any): Promise<any> => {
  const response: AxiosResponse = await api.post(url, data);
  return response;
};

export const postWithBasicAuth = async (
  url: string,
  data: any,
  username: string,
  password: string
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.post(url, data, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during POST request:", error);
  }
};

export const put = async (url: string, data: any): Promise<any> => {
  const response: AxiosResponse = await api.put(url, data);
  return response;
};

export const remove = async (url: string, data: any): Promise<any> => {
  const response: AxiosResponse = await api.delete(url, data);
  return response;
};

export const patch = async (url: string, data: any): Promise<any> => {
  const response: AxiosResponse = await api.patch(url, data);
  return response;
};
