import cx from "classnames";
import { Container } from "react-bootstrap";
import useCandidateResults from "../../hooks/useCandidateResults";
import NVBarGraph from "./NVBarGraph/NVBarGraph";
import styles from "./NVCharts.module.scss";
import RunoffGraph from "./runoff/RunoffGraph";

const NVCharts = () => {
  const candidates = useCandidateResults();
  const totalVotes =
    (candidates ?? []).reduce((acc, curr) => acc + curr.votes, 0) ?? 0;
  return (
    <Container className={cx(styles.NVChartsContainer)}>
      <Container className={cx(styles.NVBarGraphContainer)}>
        <h4 className={cx("h6")}>
          H.E Dr Netumbo Ndemupelila Nandi-Ndaitwah wins the presidential race
        </h4>
        <div className={cx(styles.runoffCandidates)}>
          <img
            src="https://static.mrcelleb.com/images/netumbo.png"
            className={cx(styles.netumbo)}
            alt="netumbo"
          />
          <img
            src="https://static.mrcelleb.com/images/itula.png"
            className={cx(styles.itula)}
            alt="itula"
          />
          <RunoffGraph candidates={candidates ?? []} />
        </div>
        <h3 className={cx("h4")}>Race to state house</h3>
        <p className={cx(styles.subHeader)}>
          total votes processed: {totalVotes}
        </p>
        <NVBarGraph candidates={candidates ?? []} totalVotes={totalVotes} />
      </Container>
    </Container>
  );
};

export default NVCharts;
