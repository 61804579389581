import ReactGA from 'react-ga4';

export const initGA = (): void => {
  ReactGA.initialize(' G-D1DXBW19GW');
};

export const logPageView = (page: string): void => {
  ReactGA.send({ hitType: 'pageview', page });
};

export const logEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
): void => {
  ReactGA.event({ category, action, label, value });
};
