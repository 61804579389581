import NVPollingStationsTable from "../../components/NVPollingStationsTable/NVPollingStationsTable";
import useCandidate from "../../hooks/useCandidate";
import usePresidentialResults from "../../hooks/usePresidentialResults";

export default function NVPresidentialTablePage() {
  const results = usePresidentialResults();
  const candidates = useCandidate() || [];

  return (
    <NVPollingStationsTable
      results={results!}
      headerText="Polling Stations"
      type="presidential"
      candidates={candidates}
    />
  );
}
