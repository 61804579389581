import cx from "classnames";
import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import {
  Candidate,
  CandidateResult,
  ParliamentResult,
  Party,
} from "../../types";
import { getPartyShortName } from "../../utils";
import styles from "./NVConstituenciesTable.module.scss";

type TableResults = CandidateResult | ParliamentResult;

interface ConstituenciesTableData {
  results: ParliamentResult[];
  headerText: string;
  candidates: Party[] | Candidate[];
  type: "presidential" | "parliamentary";
}

const NVConstituenciesTable = ({
  results,
  headerText,
  candidates,
  type,
}: ConstituenciesTableData) => {
  const [data, setData] = useState<any[]>(results);
  candidates.sort((a, b) => a.name.localeCompare(b.name));
  useEffect(() => {
    setData(results);
    const handleRightClick = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener("contextmenu", handleRightClick);

    const handleCopy = (e: ClipboardEvent) => {
      e.preventDefault();
    };

    document.addEventListener("copy", handleCopy);

    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("contextmenu", handleRightClick);
    };
  }, [results]);

  return candidates?.length > 0 && data && data?.length > 0 ? (
    <Container className={cx(styles.appContainer)}>
      <h3 className={cx(styles.header)}>
        {headerText} results: {type}
      </h3>
      <p className={cx(styles.constituenciesProcessed)}>
        Constituencies processed: {data.length}
      </p>
      <div className={cx(styles.tableContainer)}>
        <Table striped bordered hover className={cx(styles.table)}>
          <thead>
            <tr>
              <th key="candidate-name">Constituency</th>
              <th key="candidate-name">Region</th>
              {candidates.map((item: TableResults) => (
                <th key={`${item.name}`}>
                  {type === "presidential"
                    ? item.name?.split(" ")[0]
                    : getPartyShortName(item.name)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data
              .sort((a, b) => a.region.localeCompare(b.region))
              .map((station: any, index: number) => (
                <tr key={station._id.$oid}>
                  <td key={`polling-station-${index}`}>
                    {truncate(station.constituency || "", 20)}
                  </td>
                  <td key={`polling-station-${index}`}>
                    {truncate(station.region || "", 20)}
                  </td>
                  {candidates.map((candidate: TableResults, j: number) => (
                    <td key={`${candidate.name}-${station._id.$id}-${j}`}>
                      {
                        station?.outcome.find(
                          (o: TableResults) =>
                            o.identifier === candidate.identifier
                        )?.votes
                      }
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </Container>
  ) : (
    <Container className={cx(styles.appContainer)}>
      <div>...loading results</div>
    </Container>
  );
};

export default NVConstituenciesTable;

function truncate(str: string, maxLength: number) {
  return str?.length > maxLength ? str.substring(0, maxLength) + "..." : str;
}
