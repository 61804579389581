import { useEffect, useState } from "react";

type State = {
  isLoading: boolean;
  data: Record<string, unknown>;
};

const globalState = {
  isLoading: false,
  data: {},
  loading: function loading() {
    this.updateState({ isLoading: true });
    return this;
  },
  operation: async function operation(promise: Promise<unknown>) {
    this.loading();
    const response = await promise;
    this.done();
    return response;
  },
  done: function done() {
    this.updateState({ isLoading: false });
    return this;
  },
  subscribers: [] as ((state: State["data"]) => void)[],
  subscribeToStateChanges: function (callback: (state: State["data"]) => void) {
    this.subscribers.push(callback);
    callback({ ...this.data });
    return () => {
      this.subscribers = this.subscribers.filter((cb) => cb !== callback);
    };
  },
  updateState: function updateState(updates?: Partial<State["data"]>) {
    if (updates) {
      Object.assign(this.data, updates);
    }
    this.subscribers.forEach((callback) => callback({ ...this }));
  },
};

export default function useOurState() {
  const [internalState, setInternalState] = useState<State>(globalState);

  useEffect(() => {
    const unsubscribe = globalState.subscribeToStateChanges((newState) => {
      setInternalState(newState as State);
    });

    return () => unsubscribe();
  }, []);

  return internalState;
}

export function updateState(updates: Partial<State["data"]>) {
  globalState.updateState(updates);
}
