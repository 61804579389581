import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Logo from "../../assets/logo.png";

export default function NVNavbar() {
  return (
    <Navbar expand="lg" className="navbar-dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={Logo}
            width="30"
            height="30"
            style={{ marginRight: "0.5rem" }}
            className="d-inline-block align-top"
            alt="Namibia Votes Logo"
          />
          #Namvotes24
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Polling Station Results" id="basic-nav-dropdown">
                <NavDropdown.Item href="/results/stations/table/presidential">presidential</NavDropdown.Item>
                <NavDropdown.Item href="/results/stations/table/parliamentary">national assembly</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Constituency Results" id="basic-nav-dropdown">
                <NavDropdown.Item href="/results/constituencies/table/presidential">presidential</NavDropdown.Item>
                <NavDropdown.Item href="/results/constituencies/table/parliamentary">national assembly</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/map">Map</Nav.Link>
            <Nav.Link href="/candidates">Candidates</Nav.Link>
            <Nav.Link href="/parties">Parties</Nav.Link>
            <Nav.Link href="/office/login">Office</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
