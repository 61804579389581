import { useEffect, useState } from "react";
import { login } from "../api/auth";
import useOurState, { updateState } from "./state";
import { readData } from "../utils/localStorage";

interface LoginData {
  username: string;
  password: string;
}

export default function useAuth() {
  const state = useOurState();
  const [error, setError] = useState<Error | null>(null);

  const loginUser = async (data: LoginData) => {
    try {
      const user = await login(data);
      updateState({ loggedInUser: user });
      return user;
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  };

  const getLoggedInUser = () => {
    if (state?.data?.loggedInUser) {
      return state?.data?.loggedInUser;
    }
    const user = readData("user");
    if (user) {
      updateState({ loggedInUser: user });
      return user;
    }
  }

  return {
    loggedInUser: getLoggedInUser(),
    getLoggedInUser,
    loginUser,
    error,
  };
}
