import { useEffect } from "react";
import { getConstituencies, getRegions } from "../api/lists";
import useOurState, { updateState } from "./state";

export default function useLists(): Record<string, any> | undefined {
  const state = useOurState();

  useEffect(() => {
    getConstituencies().then((constituencies: any) => {
      updateState({
        lists: {
          ...(state?.data?.lists || {}),
          constituencies: constituencies.sort((a: any, b: any) => a.name.localeCompare(b.name))
        }
      });
    }
    );

    getRegions().then((regions: any) => {
      updateState({
        lists: {
          ...(state?.data?.lists || {}),
          regions: regions.sort((a: any, b: any) => a.name.localeCompare(b.name))
        }
      });
    }
    );
  }, []);

  return state?.data?.lists as Record<string, any> | undefined;
}
