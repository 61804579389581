import NVBarGraph from "../../../components/NVCharts/NVBarGraph/NVBarGraph"
import { Container } from 'react-bootstrap';
import cx from 'classnames';
import styles from './NVResultsNationalAssembly.module.scss';
import parties from "../../../data/elections.parties.json";
import usePartyResults from "../../../hooks/usePartyResults";
const sortedParties = parties.sort((a, b) => b.votes - a.votes);

export default function NVResultsNationalAssembly() {
  const results = usePartyResults();
  return (
    <Container className={cx(styles.container)}>
        <h1>Results for National Assembly</h1>
        <NVBarGraph candidates={results || []}/>
    </Container>
  )
}
