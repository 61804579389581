import { Container } from "react-bootstrap";
import NVEMap from "../../components/NVEMap/NVEMap";

export default function NVMapPage() {
  return (
    <Container>
      <NVEMap />
    </Container>
  );
}
